import React from 'react'

import '../../components/pages/GameScreen/GameScreen.css';
import './MapPath2.css';
import styled, { keyframes } from 'styled-components'


const focuspath = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Anim1 = styled.div`
  animation: ${focuspath} 1s ease-in-out;
`
const Anim2 = styled.div`
  animation: ${focuspath} 2s ease-in-out;
`
const Anim3 = styled.div`
  animation: ${focuspath} 3s ease-in-out;
`
const Anim4 = styled.div`
  animation: ${focuspath} 4s ease-in-out;
`
const Anim5 = styled.div`
  animation: ${focuspath} 5s ease-in-out;
`
const PurpleAnim = styled.div`
  animation: ${focuspath} 6s ease-in-out;
`
const round1=require('../../imgs/rounds/first.png')
const round2=require('../../imgs/rounds/second.png')
const round3=require('../../imgs/rounds/third.png')
const round4=require('../../imgs/rounds/fourth.png')
const round5=require('../../imgs/rounds/fifth.png')
const roundborder=require('../../imgs/rounds/roundborder.png')
const whitedot=require('../../imgs/rounds/whitedot.png');
const blackdot=require('../../imgs/rounds/blackdot.png');
const purple=require('../../imgs/rounds/purple.png');



function Round2() {
    
    return (
             <div className="gamescreendiv">
        
        
        <div className="pathcontainer">
          
        <img src={blackdot} className="p1"/>
        <img src={blackdot} className="p2"/>
        <img src={blackdot} className="p3"/>
        <img src={blackdot} className="p4"/>
        
        
        <img src={blackdot} className="p5"/>
        <img src={blackdot} className="p6"/>
        <img src={blackdot} className="p7"/>
        <img src={blackdot} className="p8"/>
        <img src={blackdot} className="p9"/>
        <img src={blackdot} className="p10"/>
        <img src={blackdot} className="p11"/>
        <img src={blackdot} className="p12"/>

        <img src={blackdot} className="p13"/>
        <img src={blackdot} className="p14"/>
        <img src={blackdot} className="p15"/>
        <img src={blackdot} className="p16"/>

        <img src={blackdot} className="p17"/>
        <img src={blackdot} className="p18"/>
        <img src={blackdot} className="p19"/>
        <img src={blackdot} className="p20"/>

        <img src={blackdot} className="p21"/>
        <img src={blackdot} className="p22"/>
        <img src={blackdot} className="p23"/>
        <img src={blackdot} className="p24"/>
        <img src={blackdot} className="p25"/>
        <img src={blackdot} className="p26"/>
        <img src={blackdot} className="p27"/>
        <img src={blackdot} className="p28"/>
        <img src={blackdot} className="p29"/>
        <img src={blackdot} className="p30"/>
        <img src={blackdot} className="p31"/>
        <img src={blackdot} className="p32"/>
        <img src={blackdot} className="p33"/>
        <img src={blackdot} className="p34"/>
        <img src={blackdot} className="p35"/>
        <img src={round1} className="round1"/>

        <img src={roundborder} className="roundbb12"/>
        <img src={round2} className="round2"/>
        <PurpleAnim>
        <img src={purple} className="round2h"/>

        </PurpleAnim>

        <Anim1>
        <img src={whitedot}  className="p1"/>
        </Anim1>
        <Anim2>
        <img src={whitedot} className="p2"/>
        </Anim2>
        <Anim3>
        <img src={whitedot} className="p3"/>
        </Anim3>       
        <Anim4>
        <img src={whitedot} className="p4"/>
        </Anim4>
        <Anim5>
        <img src={whitedot} className="p5"/>
        </Anim5>

        

        <img src={round3} className="round3"/>

        <img src={round4} className="round4"/>

        <img src={round5} className="round5"/>
        </div>
     


       

        
       
      
        

      </div>

    )
}

export default Round2
