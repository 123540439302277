import React from 'react';
import * as waxjs from "@waxio/waxjs/dist";

class EOSIOClient extends React.Component {
	constructor(props) {
		super(props);
		
		this.wax = new waxjs.WaxJS('https://api.wax.greeneosio.com', null, null, true);
		this.login();


	}


	login=async()=>{
		try {
			const userAccount = await this.wax.login();
			this.account=userAccount;
			console.log('acc:' + userAccount);
			this.props.setLoginDetails(this,true);
			const pubKeys = this.wax.pubKeys;
			this.publicKeys=pubKeys;
		} catch (e) {
				
		}
	}

	

	transaction = async(action, data,contract) => {
	
		return await this.wax.api.transact(
			{
				actions: [
					{
						account: contract,
						name: action,
						authorization: [
							{
								actor: this.wax.userAccount,
								permission:'active'// this.wax.userAccount.authority
							}
						],
						data: {
							...data
						}
					}
				]
			},
			{
				blocksBehind: 3,
				expireSeconds: 1800
			}
		);
	};


	
		viewTableData = async (scope, contractName, tableName, keyValue,upperkeyValue, limit,secondaryIndex) => {
			try {
				const tableQuery = {
					json: true,
					code: contractName,
					scope: scope,
					table: tableName,
					index_position: secondaryIndex?secondaryIndex:1,
					key_type: 'i64',
					lower_bound: keyValue? keyValue : keyValue,
					upper_bound: upperkeyValue? upperkeyValue : upperkeyValue,
					limit: limit ? limit : 100,
					reverse: false,            // Optional: Get reversed data
					show_payer: false,         // Optional: Show ram payer
				};
		
		
				const data = await this.wax.rpc.get_table_rows(tableQuery);
		
		
				return data;
			} catch (err) {
			}
		};

		
		
		
		
}

// function sanitizeEosioName(name) {
//     if (!isNaN(name.charAt(name.length - 1))) {
//         name += ' '; // add simple whitespace
//     }

//     return name;
// }

export default EOSIOClient;