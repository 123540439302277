import React,{useState,useEffect} from 'react'
import { useSpring, animated } from 'react-spring'
import './cardStyles.css'
import { green } from 'color-name';

const calc = (x, y) => [-(y - window.innerHeight / 2) / 20, (x - window.innerWidth / 2) / 20, 1.1]
const trans = (x, y, s) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
const mHover=  require("../../sounds/mouseclick.wav");
const mClick=  require("../../sounds/pressed.mp3");


const mclickAudio = new Audio(mClick);
const mHoverAudio = new Audio(mHover);
const playSound = audioFile => {
    audioFile.play();
  }


const c1="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Ftravel%2FELECTRIC%20CAR.png?alt=media&token=5511de2f-681c-4317-84bc-dde89f7941a7";
const c2="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Ftravel%2Fpetrol%20car.png?alt=media&token=e0a2ab3d-008e-4bf4-9e50-def6317f0960";
const c3="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Ftravel%2FDISEL%20CAR.png?alt=media&token=0ebffff3-a6bd-4da3-a5f3-c1d0ae139be3";
const c4="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Ftravel%2FCYCLE2%201.png?alt=media&token=a88d14fe-48f4-4a73-be22-1896ee7e4fa4";
const c5="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Ftravel%2FTRAIN%201.png?alt=media&token=e16cba32-f2a8-4a61-afd6-f972455b94f3";
const c6="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Ftravel%2FPLNN%201.png?alt=media&token=c89c946d-6de3-4555-aba5-2d30bd85c124";

const c7="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fappliances%2Fac.png?alt=media&token=807eeabf-d2ef-41f0-8dce-d8f62b02d336";
const c8="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fappliances%2Fcooler.png?alt=media&token=6d3378f4-3037-4c7a-9a76-a4391171443f";
const c9="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fappliances%2Ffan.png?alt=media&token=c35822be-c87e-4106-83b5-6b7eb348ea31";
const c10="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fappliances%2Fgysr.png?alt=media&token=24926927-79a3-4f65-9d47-dcae88a30766";
const c11="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fappliances%2Fmicro.png?alt=media&token=e1380f1d-0b5b-4b68-813a-dc7f7da047f7";
const c12="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fappliances%2Ffridge..png?alt=media&token=00917979-5a34-4d56-a5e0-04095c9493ae";

const c13="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fclothing%2Fcotton.png?alt=media&token=05735df2-95f1-4d4d-a11c-7dc27a3fb96c";
const c14="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fclothing%2Fwool.png?alt=media&token=5f689574-1db0-4f58-895e-41380d072713";
const c15="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fclothing%2Fpolyster.png?alt=media&token=ee0291d6-f333-4129-aba2-2f82e302341c";
const c16="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fclothing%2FLATHR%201.png?alt=media&token=24c4c0f8-3749-45ea-ac32-e048e20d3d79";
const c17="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fclothing%2FJUTE%202.png?alt=media&token=eaa282a9-fd58-43fa-9151-5098faf090c5";
const c18="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fclothing%2Fspandex.png?alt=media&token=fd57d36d-25e9-46b4-98ef-534c173aad36";

const c19="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fenergy%2FNUCLEAR.png?alt=media&token=0c220dda-f01c-4366-812e-1c8f4d613b04";
const c20="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fenergy%2Fsolar.png?alt=media&token=30549f9d-3aff-40b6-8d90-4c5aa2061a10";
const c21="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fenergy%2Fwind.png?alt=media&token=5f19ca9b-6b16-466d-901e-fbdb76e48048";
const c22="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fenergy%2Fcolan.png?alt=media&token=01f394d0-b403-4034-873d-ec0e011eb993";
const c23="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fenergy%2Fhydro.png?alt=media&token=91536a80-13c5-4cd2-9a85-78fbfd4af26d";
const c24="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Fenergy%2Fbio.png?alt=media&token=6c7c40a6-a7ee-4dd0-93b5-d9d8959e389f";


const c25="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Felements%2Fplutnm%201.png?alt=media&token=c7895104-129b-4615-90e7-9599f4a2ee78";
const c26="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Felements%2FPLASTIC%201.png?alt=media&token=be071eaa-daf6-456c-b084-7b7c22786ef7";
const c27="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Felements%2FPAPER%201.png?alt=media&token=a018caca-9be2-4cde-bde0-1f3cba66e1bc";
const c28="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Felements%2FC%20F%201.png?alt=media&token=9a32e2f5-2bf4-46e5-8c85-427850aaf309";
const c29="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Felements%2FCOPPERRRR.png?alt=media&token=ba21f6ba-73df-4e83-8cd6-fbbd05cf7402";
const c30="https://firebasestorage.googleapis.com/v0/b/battles-of-humanity.appspot.com/o/cards%2Felements%2Fal.png?alt=media&token=062c9ca0-9ea3-4e18-afc1-065e9890b87b";

const c0='';

function Card(prop) {
  
  var cno=prop.cno;
  console.log('cardno'+cno);
    
  var cardimg=eval("c"+cno);

  
  const handlePreview=(e)=>{
    e.preventDefault();
      playSound(mclickAudio);
}

// const whichPointer=(()=>{
//   if(prop.disable==true)
//     return 'not-allowed';
//   else
//     return 'pointer';
// });


  const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 350, friction: 40 } }))

  useEffect(() => {
    
  }, [])

  return (
    <animated.div
      class="card" onClick={(e) =>
        { 
          handlePreview(e);
          // prop.disableClicks();
        }
        }
      // onMouseOver={() =>playSound(mHoverAudio)}
      onMouseMove={ ({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{   
        transform: props.xys.interpolate(trans),
        // backgroundImage: `url(${cardimg})`,
      }}
    >
      <img src={cardimg} className="cardimg" onLoad={()=>{
          if(prop.finishLoad==1){
            prop.setLoader(false);
             
        }   
      }}/>
      </animated.div>
  )
  }

// }
export default React.memo(Card)
// export default Card
