import React,{useState,useEffect} from 'react'
import Pairing from './Pairing'
import './GameRoom.css';
import BottomHud from '../../BottomHud/BottomHud';
import EOSIOClientInstance from '../../../components/services/EOSIOClientInstance';
import {useLocation} from 'react-router-dom';
import { Prompt } from 'react-router'
import {useHistory} from 'react-router-dom';

import ModalPlay from '../../elements/Modalplay';
import PlayerStats from '../../elements/PlayerStats';

import { store } from 'react-notifications-component';
const smartcontractacct=process.env.REACT_APP_SMART_CONTRACT;


const mHover=  require("../../../sounds/mouseclick.wav");
const mClick=  require("../../../sounds/pressed.mp3");

const mclickAudio = new Audio(mClick);
const mHoverAudio = new Audio(mHover);
const playSound = audioFile => {
    audioFile.play();
  }

  
function GameRoom(props) {


    const history=useHistory();
    const [isPairingShown, setPairingShown] = useState(-1);
    const [activeGames, setActiveGames] = useState([]);
    const location = useLocation();
    var eosio=(EOSIOClientInstance(0));
    var accountName,balance,rewardWin,playsNo,rank;
    try{
      accountName=location.state[0].accountName;
      balance=location.state[0].balance;
      rewardWin=location.state[0].rewardWin;
      playsNo=location.state[0].playsNo;
      rank=location.state[0].rank;
      var eosioaccname=eosio.wax.userAccount;// to check if eosio obj is intact after reload

  
    }catch(e){
      history.push({
          pathname: '/',
        });
    }


    const [showPrompt,setPrompt]=useState(true);

    const showNotification=((success,msg)=>{
        if(success==true){
          store.addNotification({
            message: msg,
            type: "success",
            insert: "bottom",
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 4500,
              onScreen: false
            }
          });
        }else{
          store.addNotification({
            message: msg,
            type: "danger",
            insert: "bottom",
            // background:{backrock},
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3500,
              onScreen: false
            }
          });
        }
      });
  
    const setshowPrompt=(()=>{
      setPrompt(false);
    })

    const checkbalance=((row,fee,e)=>{
        e.preventDefault();
        // console.log(balance);
        if(balance!="0"){

          var mybal=parseFloat(balance.replace("WAX","").replace(" ",""));

          if(mybal>=fee)
              setPairingShown(row);
          else 
              showNotification(false,"You donot have enough balance. Add WAX coins to your account!");
        }else{
          setPairingShown(row);

        }

    });

    // console.log("eosio:"+eosio);

    // const handlePreview=(row,e)=>{
    //     checkbalance(row,10)
       
    // }

    useEffect(() => {
        
        try{
            eosio.viewTableData(smartcontractacct,smartcontractacct,'activegames').then(r=>{
            // console.log(r);
            if(r.rows.length>=1){
                var date = new Date();
                let utcDate = new Date(date.toLocaleString('en-US', { timeZone: "UTC" }));
                var currDate=Date.parse(utcDate);
                
                var k;
                var arr=[];
                for(k=r.rows.length-1;k>=0;k--){
                    var expiryDate=Date.parse((r.rows[k].expiry_at));
                    // alert(currDate+":"+expiryDate);
                    if(currDate<expiryDate){
                        var fees=Number(r.rows[k].fee.replace("WAX","")).toPrecision(2);
                        arr.push({fee:fees+" WAX", reward:(1.95*(fees)).toPrecision(2)+" WAX"});
                    }    
                }
                setActiveGames(arr);
            }
        });
        }catch(e){}

        return () => {
        }
    }, []);
    
        const activeGamesList=activeGames.map(g=>{
        var fee=Number(((g.fee).replace("WAX", "").replace(" ",""))).toFixed(8)+" WAX";
        return(
            <li key={g.fee} className="freebutton">
                    
              <h1 className="txtfee3">{g.fee}</h1>
              <h1 className="txtfee4">{g.reward}</h1>
              <button className="playfee" onMouseOver={() => playSound(mHoverAudio)}    
                  onClick={(e)=>{      
                  playSound(mclickAudio);
                  checkbalance(0,fee.replace("WAX","").replace(" ",""),e);
                  }}>JOIN</button>
              {isPairingShown==0 && <Pairing setshowPrompt={setshowPrompt} fee={fee}/>}


            </li>
        )
    })



  const onBeforeUnload = (event) => {
    // Prompts the user before closing the page, see:
    // https://developer.mozilla.org/en-US/docs/Web/Events/beforeunload
    event.preventDefault()
    event.returnValue = ""
  }

 window.addEventListener("beforeunload", onBeforeUnload)

    return (
  <div>

    <Prompt
    when={showPrompt}
    message="Are you sure you want to leave?"
    />

    <div className="screen">
      <div style={{flex:1}}>
        <ModalPlay eosio={eosio} accountName={accountName}/>
      </div>

     
      <div className="roomcontainer" style={{flex:1}}>
          <h1 className="headingroom">
          Choose the Game Room
          </h1>
          <div className="freecontainer"> 
          
          { activeGames.length==0 && <h1 className="txtfeenogame"> No Live Games<br/>Create the Game Room from below!</h1> }
          { activeGames.length>0 && <div className="livebuttonhead">
          <h1 className="txtfeeh">FEE</h1>
          <h1 className="txtfeeh">REWARD</h1>
          </div>}
          <div className="freebuttoncontainer">
              {activeGamesList}
          </div>
          
          </div>

          <div className="paidcontainer">
              <div className="paybuttonhead">

              <h1 className="txtfeeh">FEE</h1>
              <h1 className="txtfeeh">REWARD</h1>

              </div>
            
              <div className="paidcontainer1">
                
              <div className="paybuttonrow">
                  <h1 className="txtfee3">Free <br/>(5 Games/day)</h1>
                  <h1 className="txtfee4">0 WAX</h1>
                  <button className="playfee2" onMouseOver={() => playSound(mHoverAudio)}    
                      onClick={(e)=>{      
                      playSound(mclickAudio);
                      checkbalance(1,0,e);
                      }}
                      >CREATE</button>
                  {isPairingShown==1 && <Pairing setshowPrompt={setshowPrompt} fee="0.00000000 WAX"/>}
              </div>

              <div className="paybuttonrow">
                  <h1 className="txtfee3">10 WAX</h1>
                  <h1 className="txtfee4">18 WAX</h1>
                  <button className="playfee2" onMouseOver={() => playSound(mHoverAudio)}    
                      onClick={(e)=>{      
                      playSound(mclickAudio);
                      checkbalance(2,10,e);
                      }}
                      >CREATE</button>
                  {isPairingShown==2 && <Pairing setshowPrompt={setshowPrompt} fee="10.00000000 WAX"/>}
              </div>

              <div className="paybuttonrow">
                  <h1 className="txtfee3">50 WAX</h1>
                  <h1 className="txtfee4">90 WAX</h1>
                  <button className="playfee2" onMouseOver={() => playSound(mHoverAudio)}    
                      onClick={(e)=>{      
                      playSound(mclickAudio);
                      
                        checkbalance(3,50,e);
                      }} >CREATE</button>
                      {isPairingShown==3 && <Pairing setshowPrompt={setshowPrompt} fee="50.00000000 WAX"/>}
              </div>
              
              <div className="paybuttonrow">
                  <h1 className="txtfee3">100 WAX</h1>
                  <h1 className="txtfee4">180 WAX</h1>
                  <button className="playfee2"onMouseOver={() => playSound(mHoverAudio)}    
                      onClick={(e)=>{      
                      playSound(mclickAudio);
                      checkbalance(4,100,e);
                  }}>CREATE</button>
                  {isPairingShown==4 && <Pairing setshowPrompt={setshowPrompt} fee="100.00000000 WAX"/>}

              </div>

              <div className="paybuttonrow">
                  <h1 className="txtfee3">500 WAX</h1>
                  <h1 className="txtfee4">900 WAX</h1>
                  <button className="playfee2"onMouseOver={() => playSound(mHoverAudio)}    
                  onClick={(e)=>{      
                  playSound(mclickAudio);
                  checkbalance(5,500,e);
              }}
                  >CREATE</button>
                  {isPairingShown==5 && <Pairing setshowPrompt={setshowPrompt} fee="500.00000000 WAX"/>}

              </div>
            </div>
          </div>
      </div>

       <div style={{flex:1}}> 
      <PlayerStats rank={rank} rewardWin={rewardWin} playsNo={playsNo} accountName={accountName} balance={balance}/>
      </div>
         
     </div>

        <footer >
          <BottomHud start="false" oppwins="0" mywins="0"/>
        </footer>

     
        </div>
    )
}

export default GameRoom