import React,{Component,useState,useEffect,useReducer} from 'react'
import './GameScreen.css';
import '../../rounds/MapPath.css';

import Rounds from '../../rounds/Rounds';
import { Prompt } from 'react-router'

import BottomHud from '../../BottomHud/BottomHud'
import {useLocation} from 'react-router-dom';
import ChooseCard from './ChooseCard';
import EOSIOClientInstance from '../../services/EOSIOClientInstance';
import {useHistory} from 'react-router-dom';
import ResultScreen from './ResultScreen';
import AvatarHost from '../../Avatar/AvatarHost';


const smartcontractacct=process.env.REACT_APP_SMART_CONTRACT;
const howtoplay=require('../../../imgs/howtoplay.png')



const initialPlayerStats={
  myBpoints:30,
  oppBpoints:30,
  myWins:0,
  oppWins:0
}

function playerStats(state,action){
  //prev state not reqd
  return action;
}

const initialRound={
  rno:1,
  RoundsData:[]
}

function reducerRound(state,action){
  //prev state not reqd
  return action;
}


export default function GameScreen() {

    const location = useLocation();
    const history=useHistory();

    var name, oppname, playerNo, game_id;
    try{
      name = location.state[0].name;
      oppname = location.state[0].oppname;
      playerNo = location.state[0].playerNo;
      game_id = location.state[0].game_id;

    }catch(e){
      history.push({
        pathname: '/',
      });
    }


    const [isWinnerShown, setWinnerShown] = useState(0);
    const [isChooseCardShown, setChooseCardShown] = useState(true);
    const [howScreen,setHowScreen]=useState(false);

    const [statePlayerStats, dispatchPlayerStats] = useReducer(playerStats, initialPlayerStats);
    const [round, dispatchRound] = useReducer(reducerRound, initialRound);

   
    const updateBottomHud=(()=>{
      eosio.viewTableData(smartcontractacct,smartcontractacct,'gamesinfo',game_id,game_id
      ).then(r=>{
          // console.log(r);
         if(r.rows.length>=1){
            if((r.rows[0].p2_card_no!=0)&&(r.rows[0].p1_card_no!=0)) 
            {  
                if(playerNo==1) {
                  //implement reducers later
                  dispatchPlayerStats({myBpoints:r.rows[0].p1_bpoints,
                    oppBpoints:r.rows[0].p2_bpoints,
                    myWins:r.rows[0].p1_wins,
                    oppWins:r.rows[0].p2_wins
                  });
                  if(round.rno==5){
                    // you won/lost last dialog box
                    if(r.rows[0].p1_wins>r.rows[0].p2_wins)
                      setWinnerShown(1);
                    else if(r.rows[0].p1_wins<r.rows[0].p2_wins)
                    setWinnerShown(2);
                    else setWinnerShown(3);
              
                    setChooseCardShown(false);
                  }
  

                }else{
                  dispatchPlayerStats({myBpoints:r.rows[0].p2_bpoints,
                    oppBpoints:r.rows[0].p1_bpoints,
                    myWins:r.rows[0].p2_wins,
                    oppWins:r.rows[0].p1_wins
                  });

                  if(round.rno==5){
                    // you won/lost last dialog box
                    if(r.rows[0].p1_wins<r.rows[0].p2_wins)
                      setWinnerShown(1);
                    else if(r.rows[0].p1_wins>r.rows[0].p2_wins)
                    setWinnerShown(2);
                    else setWinnerShown(3);
              
                    setChooseCardShown(false);
                  }
  
                }

               

              }
            }});
    })

    const onBeforeUnload = (event) => {
      // Prompts the user before closing the page, see:
      // https://developer.mozilla.org/en-US/docs/Web/Events/beforeunload
      event.preventDefault()
      event.returnValue = ""
    }
  
   window.addEventListener("beforeunload", onBeforeUnload)
  

    var eosio=(EOSIOClientInstance(0));
    // console.log("eosio:"+eosio);
    const changeRound=(()=>{

    // console.log("Round no GS:"+round.rno);

   
     
    try{
            updateBottomHud();
            eosio.viewTableData(game_id,smartcontractacct,'roundev').then(r=>{
              // console.log(r);
             if(r.rows.length>=1){
              var k;
              var RoundsArr=[];
              for(k=0;k<r.rows.length;k++){
                    if(r.rows[k].winner==playerNo) {
                      RoundsArr.push(1);
                    }else if(r.rows[k].winner==0){
                      console.log('Waiting for other player');
                    }else if(r.rows[k].winner==3){
                      console.log('Draw match');
                      RoundsArr.push(0);
                    }
                    else{
                      RoundsArr.push(-1);
                    }
                    if(k==r.rows.length-1){

                      if(round.rno==5){
                       dispatchRound({
                          rno:0,
                          RoundsData:RoundsArr
                        });
                      }else{
                        dispatchRound({
                          rno:RoundsArr.length+1,
                          RoundsData:RoundsArr
                        });
                      }
                      // setRoundsData(RoundsArr);
                      // setround(newRound);
                    }
              }
              // startchangeRound();

            }});


        }catch(e){
            console.log('Exception: '+e);
        }

      
  });

  return (
    <div>
         <Prompt
            when={isWinnerShown==0}
            message="Are you sure you want to leave? Note: You will lose the game."
            />

      <div className="screens">
      
      {isChooseCardShown && <ChooseCard rno={round.rno} game_id={game_id} changeRound={changeRound} playerNo={playerNo} setWinnerShown={setWinnerShown}/> }
      
      {isWinnerShown>0 && <ResultScreen win={isWinnerShown}/>}

      

      {round.rno!=0 && <Rounds rno={round.rno}/>}

      <div className="sidebarend">
      {round.rno!=0 && <h1 className="txtr">ROUND</h1>}
        {round.rno!=0 && <h1 className="txtrno">{round.rno}</h1>}
         </div>

      {round.rno!=0 && <button className="howbutton" onClick={()=>{
        setHowScreen(true);
      }}
      >How To Play</button>}
    
    {howScreen==true && 
    <div style={{backgroundImage:`url(${howtoplay})`}} className="view100">
      <button className="cancelbutton" onClick={()=>{
                  setHowScreen(false);
      }}></button>
      </div>
      } 
        <AvatarHost round={round.rno}
         RoundsData={round.RoundsData}/>
        
    </div>

    <footer >   
      <BottomHud start="true" oppwins={statePlayerStats.oppWins} mywins={statePlayerStats.myWins} mybpoints={statePlayerStats.myBpoints} oppbpoints={statePlayerStats.oppBpoints} myname={name} oppname={oppname} RoundsData={round.RoundsData}/>
    </footer>

    </div>
    )
}
