import React from 'react'

import '../../components/pages/GameScreen/GameScreen.css';
import './MapPath2.css';
import styled, { keyframes } from 'styled-components'


const focuspath = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Anim1 = styled.div`
  animation: ${focuspath} 0.5s ease-in-out;
`
const Anim2 = styled.div`
  animation: ${focuspath} 1s ease-in-out;
`
const Anim3 = styled.div`
  animation: ${focuspath} 1.5s ease-in-out;
`
const Anim4 = styled.div`
  animation: ${focuspath} 2s ease-in-out;
`
const Anim5 = styled.div`
  animation: ${focuspath} 2.5s ease-in-out;
`
const Anim6 = styled.div`
  animation: ${focuspath} 3s ease-in-out;
`
const Anim7 = styled.div`
  animation: ${focuspath} 3.5s ease-in-out;
`
const Anim8 = styled.div`
  animation: ${focuspath} 4s ease-in-out;
`
const Anim9 = styled.div`
  animation: ${focuspath} 4.5s ease-in-out;
`
const Anim10 = styled.div`
  animation: ${focuspath} 5s ease-in-out;
`
const Anim11 = styled.div`
  animation: ${focuspath} 5.5s ease-in-out;
`
const Anim12 = styled.div`
  animation: ${focuspath} 6s ease-in-out;
`
const Anim13 = styled.div`
  animation: ${focuspath} 6.5s ease-in-out;
`
const Anim14 = styled.div`
  animation: ${focuspath} 7s ease-in-out;
`
const Anim15 = styled.div`
  animation: ${focuspath} 7.5s ease-in-out;
`

const PurpleAnim = styled.div`
  animation: ${focuspath} 6s ease-in-out;
`

const round1=require('../../imgs/rounds/first.png')
const round2=require('../../imgs/rounds/second.png')
const round3=require('../../imgs/rounds/third.png')
const round4=require('../../imgs/rounds/fourth.png')
const round5=require('../../imgs/rounds/fifth.png')
const roundborder=require('../../imgs/rounds/roundborder.png')
const whitedot=require('../../imgs/rounds/whitedot.png');
const blackdot=require('../../imgs/rounds/blackdot.png');
const purple=require('../../imgs/rounds/purple.png');



function Round5() {
    
    return (
             <div className="gamescreendiv">
        
        
        <div className="pathcontainer">
          
        <img src={whitedot} className="p1"/>
        <img src={whitedot} className="p2"/>
        <img src={whitedot} className="p3"/>
        <img src={whitedot} className="p4"/>
        
        
        <img src={whitedot} className="p5"/>
        <img src={whitedot} className="p6"/>
        <img src={whitedot} className="p7"/>
        <img src={whitedot} className="p8"/>
        <img src={whitedot} className="p9"/>
        <img src={whitedot} className="p10"/>
        <img src={whitedot} className="p11"/>
        <img src={whitedot} className="p12"/>

        <img src={whitedot} className="p13"/>
        <img src={whitedot} className="p14"/>
        <img src={whitedot} className="p15"/>
        <img src={whitedot} className="p16"/>

        <img src={whitedot} className="p17"/>
        <img src={whitedot} className="p18"/>
        <img src={whitedot} className="p19"/>
        <img src={whitedot} className="p20"/>

        <img src={whitedot} className="p21"/>
        <img src={blackdot} className="p22"/>
        <img src={blackdot} className="p23"/>
        <img src={blackdot} className="p24"/>
        <img src={blackdot} className="p25"/>
        <img src={blackdot} className="p26"/>
        <img src={blackdot} className="p27"/>
        <img src={blackdot} className="p28"/>
        <img src={blackdot} className="p29"/>
        <img src={blackdot} className="p30"/>
        <img src={blackdot} className="p31"/>
        <img src={blackdot} className="p32"/>
        <img src={blackdot} className="p33"/>
        <img src={blackdot} className="p34"/>
        <img src={blackdot} className="p35"/>
        <img src={round1} className="round1"/>
        <img src={round2} className="round2"/>
        <img src={round3} className="round3"/>
        <img src={round4} className="round4"/>

       

        <Anim1>
        <img src={whitedot}  className="p22"/>
        </Anim1>
        <Anim2>
        <img src={whitedot} className="p22"/>
        </Anim2>
        <Anim3>
        <img src={whitedot} className="p23"/>
        </Anim3>       
        <Anim4>
        <img src={whitedot} className="p24"/>
        </Anim4>
        <Anim5>
        <img src={whitedot} className="p25"/>
        </Anim5>
        <Anim6>
        <img src={whitedot}  className="p26"/>
        </Anim6>
        <Anim7>
        <img src={whitedot} className="p27"/>
        </Anim7>
        <Anim8>
        <img src={whitedot} className="p28"/>
        </Anim8>       
        <Anim9>
        <img src={whitedot} className="p29"/>
        </Anim9>
        <Anim10>
        <img src={whitedot} className="p30"/>
        </Anim10>
        <Anim11>
        <img src={whitedot} className="p31"/>
        </Anim11>
        <Anim12>
        <img src={whitedot} className="p32"/>
        </Anim12>
        <Anim13>
        <img src={whitedot} className="p33"/>
        </Anim13>
        <Anim14>
        <img src={whitedot} className="p34"/>
        </Anim14>
        <Anim15>
        <img src={whitedot} className="p35"/>
        </Anim15>

        <img src={roundborder} className="roundbb45"/>
        <img src={round5} className="round5"/>
        <PurpleAnim>
        <img src={purple} className="round5h"/>

        </PurpleAnim>

        </div>
     


       

        
  
        

      </div>

    )
}

export default Round5
