import React,{useRef,useState,useEffect} from 'react';
import './ChooseCard.css';
import '../../cards/CardFlip.css';
import ReactCardFlip from 'react-card-flip';
import { store } from 'react-notifications-component';
import { useSelector, useDispatch } from 'react-redux'
import { Preloader, ThreeDots } from 'react-preloader-icon';

import Card from '../../cards/Card'
import CardsDeck from '../../cards/CardsDeck';
import { Spring } from 'react-spring/renderprops';
import {
    Link,
    DirectLink,
    Element,
    Events,
    animateScroll,
    scrollSpy,
    scroller
  } from "react-scroll";
  
import {useHistory} from 'react-router-dom';

import EOSIOClientInstance from '../../services/EOSIOClientInstance';

const smartcontractacct=process.env.REACT_APP_SMART_CONTRACT;

const firebase = require("firebase");
require("firebase/functions");

const frontr1=require('../../../imgs/cards/travel/front.png');
const frontr4=require('../../../imgs/cards/energy/front.png');
const frontr5=require('../../../imgs/cards/elements/front.png');
const frontr3=require('../../../imgs/cards/clothing/front.png');
const frontr2=require('../../../imgs/cards/appliances/front.png');
const clockimg=require('../../../imgs/clock.png')

const pr=require('../../../imgs/cards/pr.png')
const cr=require('../../../imgs/cards/cr.png')
const arrow=require('../../../imgs/cards/arrow.png')
const arrowBottom=require('../../../imgs/cards/arrow-bottom.png')
const mTick=  require("../../../sounds/tick.mp3");
const mTickAudio=new Audio(mTick);
const mTimerRunout=  require("../../../sounds/timerrunout.m4a");
const mTimerRunoutAudio=new Audio(mTimerRunout);

const playSound = audioFile => {
  audioFile.play();
}
var countwait=0;

function ChooseCard(props) {

    const ref = useRef(null);
    const ref1 = useRef(null);
    const history=useHistory();

    const playerNo=props.playerNo;
    const gameId=props.game_id;
    const [cardChose,setCardChose]=useState(0);
   
    const [initialAnim,setInitialAnim]=useState(-100);
    const [finalAnim,setFinalAnim]=useState(-1000);
    const[frontcard,setFrontCard]=useState(frontr1);
    const [loader,setLoader]=useState(true);

    var eosio=(EOSIOClientInstance(0));
    // console.log("eosio:"+eosio);
    const [isFlipped, setIsFlipped] = useState(false);
    const [isOppFlipped, setIsOppFlipped] = useState(false);
    const [oppCardChose,setOppCardChose]=useState(0);
    const [arrowRotate, setArrowRotate]=useState("arrow-rotate");
    const [statustxt, setStatustxt]=useState("wait");
    const[rno, setRno]=useState(props.rno);



    const validate=async(roundno)=>{

      try {
          var validatefn = firebase.functions().httpsCallable('validate');
          validatefn({game_id: gameId, roundno:roundno, player_no:playerNo });//.then(function(result) {
      } catch (err) {
        alert (err+gameId+" "+roundno+" "+playerNo);
        return err;
      }   
  }



    const handleFlip = () => {
      setIsFlipped(true);
    };
    

    const showNotification=((success,msg)=>{
        if(success==true){
          store.addNotification({
            message: msg,
            type: "success",
            insert: "bottom",
            // background:{backrock},
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false
            }
          });
        }else{
          store.addNotification({
            // title: "Wonderful!",
            message: msg,
            type: "danger",
            insert: "bottom",
            // background:{backrock},
            container: "bottom-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: false
            }
          });
        }
      });

      const cardChoose=((cno)=>{
          // console.log("Card Chosen");
          // may choose less card
          if(rno==5){
            const bp=[0,3,8,7,5,6];//bps of last round to check

            eosio.viewTableData(smartcontractacct,smartcontractacct,'gamesinfo',gameId,gameId
            ).then(r=>{
                // console.log(r);
               if(r.rows.length>=1){
                  if(playerNo==1) {
                    //implement reducers later
                    var myBpoints=r.rows[0].p1_bpoints;
                    if(bp[cno-1]<=myBpoints){
                      setCardChose(cno);
                      goToBattle();    
                    }else{
                      showNotification(false,"Your Battle Points remaining are lesser than selected card's BP");
                    }
    
                    }
                    
                    else{
                    var myBpoints=r.rows[0].p2_bpoints;
                    if(bp[cno-1]<=myBpoints){
                      setCardChose(cno);
                      goToBattle();    
                    }else{
                      showNotification(false,"Your Battle Points remaining are lesser than selected card's BP");
                    }
    
                  }

              }
                }).catch(e=>{
                  showNotification(false,"Something went wrong");
                });
              }

              else if(rno==4){
                const bp=[6,6,9,3,6,7];//bps of second last round to check
    
                eosio.viewTableData(smartcontractacct,smartcontractacct,'gamesinfo',gameId,gameId
                ).then(r=>{
                    // console.log(r);
                   if(r.rows.length>=1){
                      if(playerNo==1) {
                        //implement reducers later
                        var myBpoints=r.rows[0].p1_bpoints;
                        if(bp[cno-1]<=myBpoints){
                          setCardChose(cno);
                          goToBattle();    
                        }else{
                          showNotification(false,"Your Battle Points remaining are lesser than selected card's BP");
                        }
        
                        }

                    else{
                      var myBpoints=r.rows[0].p2_bpoints;
                      if(bp[cno-1]<=myBpoints){
                        setCardChose(cno);
                        goToBattle();    
                      }else{
                        showNotification(false,"Your Battle Points remaining are lesser than selected card's BP");
                      }
      
                    }
  
                }
                  }).catch(e=>{
                    showNotification(false,"Something went wrong");
                  });
              }
  
              
              // normal select
              else{
              setCardChose(cno);
              goToBattle();
            }
          });


    
    var accountName="Player";
    try{
    accountName=eosio.wax.userAccount;
    }catch(e){
      showNotification(false,"Connection to WAX Cloud failed, please restart the game!");
    }

    var currround=0;
    
    // if other player left
    const gameresultleft=(()=>{
      const actionName = "findresult";
      const actionData = {
        gid:gameId,
      };

      // console.log(`Sending Transaction by ${accountName}!`);
      try {
      const result =  eosio.transaction(actionName, actionData,smartcontractacct).then((r=>{
          // console.log(r+"\on blockchain successful");    
          props.setWinnerShown(true);
          showNotification(true, "Further match is abandoned due to inactivity of other player. You will win the match if you played more rounds than other player.");

          history.push("/");

      })
      ,(e=>{
          console.log("\nCaught exception in transaction: " + e);
          showNotification(false, String(e));  

           }));
  
      // console.log(result);
  
      } catch (e) {
          console.log("\nCaught exception: " + e);
          showNotification(false, String(e));  
        }
    

    });

    const transactround=(()=>{

      var cardno;
      var roundno=rno;
      if(cardChose==0){
        var random=Math.floor(Math.random() * 6)+1;     // returns a random integer from 0 to 9
        if(rno!=5)
          cardno=random;
        else cardno=1;
        cardChoose(cardno);
        showNotification(true,"Card auto-selected as your timer ran out");

      }else{
        cardno=cardChose;
        cardChoose(cardno);
      }
      

      if(currround<roundno){
        currround=currround+1;
        const actionName = "submitround";
        const actionData = {
          account:accountName,
          game_id:gameId,
          card_no:cardno,
          round_no:roundno,
          timeremaining:0
        };
  
        // console.log(`Sending Transaction by ${accountName}!`);
        try {
        const result =  eosio.transaction(actionName, actionData,smartcontractacct).then((r=>{
            // console.log(r+"\on blockchain successful");    
            validate(roundno);    
        })
        ,(e=>{
            console.log("\nCaught exception in transaction: " + e);
            showNotification(false, String(e));  

             }));
    
        // console.log(result);
    
        } catch (e) {
            console.log("\nCaught exception: " + e);
            // showNotification(false, String(e));  
          }
      }
    
    })


    const startAnotherRound=(()=>{

        setTimeout(()=>{
            setInitialAnim(-100);
            setFinalAnim(-1000);
        },100);
    })


    useEffect(()=>{
      if(loader==false){
        setTimeout(()=>{
          setInitialAnim(0);
          setFinalAnim(-100);
          goBackToChoose();
          startTimer();

      },3000);
    }
    },[loader]);

  

const resetCards=(()=>{
    setArrowRotate("arrow-rotate");
    setIsFlipped(false);
    setIsOppFlipped(false);
    setCardChose(0);
    setOppCardChose(0);
    setStatustxt("wait");
    countwait=0;

    // setSelectedCardNo(0);

    if(rno==1)
    setFrontCard(frontr2);
    else if(rno==2)
    setFrontCard(frontr3);
    else if(rno==3)
    setFrontCard(frontr4);
    else if(rno==4)
    setFrontCard(frontr5);

    startAnotherRound();
});

    useEffect(()=>{

        const interval = setInterval(() => {
            // console.log('Checking opp cards every 3 secs, Round:',rno);
            checkForOppCard(interval);
            countwait++;
            //15
            if(countwait==7){
                gameresultleft();
            }
        }, 5000);//5000

        return () => {
            clearInterval(interval);
            countwait=0;
        }

    },[rno]);



    const checkForOppCard=((interval)=>{

        // console.log('This will run every 1 second!');
           
        try{
            //props.game_id
              eosio.viewTableData(gameId,smartcontractacct,'roundev').then(r=>{
                // console.log('Round:' +rno);
                // console.log(r);
                 if(r.rows.length>=1){

                    if(r.rows[rno-1].deciding_factor==1)
                        setArrowRotate("arrow-rotate-pr");
                    else if(r.rows[rno-1].deciding_factor==2)
                        setArrowRotate("arrow-rotate-cr");

                    if(((r.rows[rno-1].p2_card_no!=0)&&(r.rows[rno-1].p1_card_no!=0)) &&
                    ((rno>=2 &&(r.rows[rno-2].valid_p1!=0)&&(r.rows[rno-2].valid_p2!=0))||(rno==1))) 
                    {  
                      
                      if(playerNo==1){
                          setOppCardChose(r.rows[rno-1].p2_card_no);
                      }
                      else{
                          setOppCardChose(r.rows[rno-1].p1_card_no);
                      }

                      switch (r.rows[rno-1].winner) {
                        case playerNo:
                          setStatustxt("win");
                          break;
                        case 3:
                          setStatustxt("draw");
                          break;
                        case (3-playerNo):
                          setStatustxt("lose");
                        default:
                          break;
                      }
                      // if(r.rows[0].winner==props.playerNo) setStatustxt("win"); else if(r.rows[0].winner==3) setStatustxt("draw"); else if(r.rows[0].winner==(3-props.playerNo)) setStatustxt("lose");

                        setIsOppFlipped(true);
                        clearInterval(interval);

                        
                        // show winner and then..
                        setTimeout(()=>{
                          resetCards();
                          setLoader(true);
                          props.changeRound();
                          setTimeout(()=>{

                          if(rno!=5)
                            setRno(rno+1);
                            // props.changeRound(startAnotherRound);
                          },3000);
                          
                        },7000);


                      }
                    }});
              
                    
                }catch(e){
                    console.log('Exception: '+e);
                }
    });

    
     const goToBattle=(() =>{   
      scroller.scrollTo("cardcontainerid", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        containerId: "scroll-container",
      })
      handleFlip();
    }
    );
  
    const goBackToChoose=(() =>{   
        scroller.scrollTo("choosecontainerid", {
          duration: 800,
          delay: 0,
          smooth: "easeInOutQuart",
          containerId: "scroll-container",
        })
      }
      );
    
    
    const scroll = (scrollOffset) => {
        ref.current.scrollLeft += scrollOffset;
      };

      const computeCNo=(cno)=>{
        return cno+(6*(rno-1));
      };

      const [countdownTimer,setCountdownTimer]=useState("30");
      // const [timer,setTimer]=useState(0);
  
      useEffect(() => {
        if(countdownTimer==0){
          // console.log("CC:"+cardChose);
          transactround();
        }

        return () => {
          
        }
      }, [countdownTimer]);

      var timer=0;
      const startTimer=(() => {
        playSound(mTickAudio);
        const interval1 = setInterval(() => {
          timer=(timer+1);
          var presec="",sec=20-timer;
          if(parseInt(sec)/10<1) presec="0";
          if(sec>=0)
            setCountdownTimer(presec+sec);
          if(sec==10){
            playSound(mTimerRunoutAudio);
          }
       
          if(timer==0)
          clearTimeout(interval1);
        }, 1000);
  
      });
  
    return (
      <div>
      <div className="sidebarstart" style={{padding:'15px'}}>
        <img src={clockimg}/>
        <h1 className="timertxt" style={{padding:'10px'}}>0:{countdownTimer}</h1>
      </div>

  <Spring 
   
    from={{opacity:0, marginTop:initialAnim }}  
    to={{opacity:1, marginTop:finalAnim }}
  
    config={{duration:500 }}
      >
        {props1=>(
              <div id="scroll-container" className="bcontainer" style={props1} ref={ref1} > 
                 <div id="choosecontainerid" className="boardcontainer" >
                 
                 <button className="nextbutton" onClick={()=>scroll(200)}></button>
                 <button className="backbutton" onClick={()=>scroll(-200)}></button>

                    <div className="vscontainer" ref={ref}>
                        <div className="slide" onClick={()=>
                            {
                                cardChoose(1);
                            }
                            }><Card cno={computeCNo(1)} /></div>
                        <div className="slide" onClick={()=>
                            {
                                cardChoose(2);

                            }
                            }><Card  cno={computeCNo(2)}  /></div>
                        <div className="slide" onClick={()=>
                            {
                                cardChoose(3);
                            }
                            }><Card  cno={computeCNo(3)}  /></div>
                        <div className="slide" onClick={()=>
                            {
                                cardChoose(4);
                            }}><Card cno={computeCNo(4)}/></div>
                        <div className="slide" onClick={()=>
                            {
                                cardChoose(5);
                            }
                            }><Card cno={computeCNo(5)}/></div>
                        <div className="slide" onClick={()=>
                            {
                                cardChoose(6);
                            }
                            }><Card cno={computeCNo(6)} finishLoad="1" setLoader={setLoader}/></div>

                    </div>

                    <div id="cardcontainerid" className="cardcontainer">
                    
                    <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">

                    <div>
                      <div style={{backgroundImage:`url(${frontcard})`}} className="disabledcard"/>
                    </div>

                    <div>
                      <Card cno={computeCNo(cardChose)}/>
                    </div>

                    </ReactCardFlip>

                   

                    <div className="headingchoose">
                        <h1 className="statusvs">V/S</h1>
                        { statustxt=="wait" && <h1 className="statusvs" >Waiting</h1>}
                        { statustxt=="wait" &&  <Preloader
                            use={ThreeDots}
                            size={50}
                            strokeWidth={6}
                            strokeColor="#262626"
                            duration={2000}
                          />}
                       
                       { statustxt=="draw" && <h1 className="statusdraw" >DRAW</h1> }       
                       { statustxt=="win" && <h1 className="statuswin" >YOU WON</h1> }       
                       { statustxt=="lose" && <h1 className="statuslose" >YOU LOST</h1> }       

                        <div className="rowarrow">
                          <img src={pr} className="prpos"/>
                          <img src={cr} className="crpos"/>
                        </div>
                        <img src={arrow} className={arrowRotate}/>
                        <img src={arrowBottom} className="arrowcontainer"/>
                        </div>

                    <ReactCardFlip isFlipped={isOppFlipped} flipDirection="horizontal">
                        <div>
                          <div style={{backgroundImage:`url(${frontcard})`}} className="disabledcard"/>
                        </div>
                        <div>
                          <Card cno={computeCNo(oppCardChose)}/>
                        </div>
                    </ReactCardFlip>

                </div>   
                
                </div>

              </div> 
            )}
      
      </Spring>

      </div>
    )
}

export default ChooseCard