import React,{Component} from 'react';
import './App.css';
import SplashScreen from './components/pages/Splash/SplashScreen'
import GameScreen from './components/pages/GameScreen/GameScreen'
import GameRoom from './components/pages/GameRoom/GameRoom'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'


class App extends React.Component {
  
  componentDidMount() {
    this.props.hideLoader();
  }

  render() {   
    return (
      <Router>
        <div className="App">

        <ReactNotification />
        <Switch>       
        <Route exact path="/" strict component={SplashScreen}/>
        <Route path="/refer" render={()=>{
          return (<SplashScreen referred="1"/>)
        }}/>

        <Route path="/choose" exact strict render={()=>{
          return (<h1>Choose Game</h1>)
        }}/>
        <Route exact path="/room" strict component={GameRoom}/>

        <Route exact path="/game" strict component={GameScreen}/>
        </Switch>

      </div>
      </Router>
    ); 
  }
}



// // import ArtboardComponent from './components/comp/ArtboardComponent'
// class App extends Component {
//   render(){
//     return (
//       <Router>
//       <div className="App">

//         <ReactNotification />
//         <Switch>       
//         <Route exact path="/" strict component={SplashScreen}/>

//         <Route path="/choose" exact strict render={()=>{
//           return (<h1>Choose Game</h1>)
//         }}/>
//         <Route exact path="/room" strict component={GameRoom}/>

//         <Route exact path="/game" strict component={GameScreen}/>
//         </Switch>

        
// </div>
// </Router>
        
//     );
//   }
// }
export default App;