import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const player1pic = require('../../imgs/player1.png');
const coins = require('../../imgs/coins.png');
const borderleader = require('../../imgs/playerstat/borderleader.png');
const rankpic=require('../../imgs/playerstat/rankpic.png');
const rewardpic=require('../../imgs/playerstat/rewardpic.png');
const gamespic=require('../../imgs/playerstat/gamespic.png');
const textbox=require('../../imgs/textbox.png');

const useStyles = makeStyles(theme => ({
    col: {
        display:'flex',
        flexDirection:'column',
        paddingLeft:'5px',
        paddingTop:'5px',
        flex:'1',
        
        objectFit:'contain',
        justifyContent: 'space-around', 
        alignItems: 'space-around'
      
    },
    row: {
        flex:'1',
        paddingTop:'10px',
        paddingBottom:'6px',
        paddingLeft:'15px',
        paddingRight:'15px',
        display:'flex',
        flexDirection:'row',
        color: 'bisque',
        justifyContent: 'flex-start',
        alignItems:'flex-start', 

        fontSize: '22px',
        background:'transparent',
        fontFamily: 'Courier New'

    },
    imgtopright:{
        flex:1,
        paddingRight: '8px',
        objectFit: 'contain'
    },
    
    texttopright:{
        flex:4,
        color: 'khaki',
        fontSize: '18px',
        paddingTop:'5%',
        paddingRight: '3%',    
        fontFamily: 'Courier New'
    }
    
}));

function PlayerStats(props) {
    const classes = useStyles();

    return (
        <div style={{display:'flex', flexDirection:"column", justifyContent:'space-around', alignItems:'flex-end', margin:'3%'}}>
                <div className={classes.col} style={{flex:1, justifyContent:'space-around', alignItems:'flex-end', paddingEnd:'5%'}}>
                    <div className={classes.row} style={{ backgroundImage: `url(${textbox})`, width:'130%', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', objectFit:"contain"}} >
                        <img className={classes.imgtopright} src={player1pic}/>
                        <h1 className={classes.texttopright}>{props.accountName}</h1>
                    </div>
                    <div className={classes.row} style={{ backgroundImage: `url(${textbox})`, width:'130%',backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', objectFit:"contain"}} >
                        <img className={classes.imgtopright}src={coins} />
                        <h1 className={classes.texttopright}>{props.balance} WAX</h1>
                    </div>
                </div>

        <div style={{display:'flex',flex:1, justifyContent:'flex-end', alignItems:'flex-end',paddingTop:'3%'}}>
        <div className={classes.col} style={{flex:1, backgroundImage:`url(${borderleader})`, backgroundRepeat:'no-repeat', padding:'10px',backgroundSize:'100% 100%', margin:'2%', zIndex:50}}>
            <div className={classes.row}>
                <img src={rankpic}/>
                <h1 className={classes.row}> {props.rank}<br/>RANK</h1> 
            </div>

            <div className={classes.row}>
                <img src={rewardpic}/>
                <h1 className={classes.row}> {props.rewardWin}<br/>WAX</h1> 
            </div>

            <div className={classes.row}>
                <img src={gamespic} />
                <h1 className={classes.row}> {props.playsNo} <br/>GAMES</h1> 
            </div>

        </div>
        </div>
    </div>)
}

export default PlayerStats
