import React from 'react'
import Avatar from 'avataaars'
import Typing from 'react-typing-animation';

function AvatarR2(props) {
    var avatartext="",avatartext2="",avatartext3="";
    var face="Smile",clotheColor="PastelBlue";
    const RoundsData=props.RoundsData;

    if(RoundsData.length>=1){
        if(RoundsData[0]==1){
          clotheColor="PastelGreen";
          avatartext="You won the round. Just 2 more to go!";
        }else if(RoundsData[0]==-1){
            face="Concerned";
            clotheColor="Red";
          avatartext="You lost the first round. You can still win the game by winning others";
      }else if(RoundsData[0]==0){
          face="Twinkle";
          avatartext="The first round was draw. All the best for remaining rounds!";
      }
    }
      avatartext2=("Let's start with ROUND 2, Category: APPLIANCES");
  
    return (
        <div>
            
            <div className="bubblestyle">
            <Typing className="bubbletext" speed={50}>
            <Typing.Reset count={1} delay={0}/>

            { avatartext!="" &&  <span>{avatartext}</span>}
            { avatartext2!="" && <Typing.Reset count={1} delay={2000} />}
            { avatartext2!="" &&  <span>{avatartext2}</span>}
            
            </Typing>
            </div>
   
     <div className="avatarstyle">
     <Avatar
      avatarStyle='Transparent'
      topType='Hat'
      accessoriesType='Kurt'
      facialHairType='MoustacheMagnum'
      facialHairColor='Black'
      clotheType='CollarSweater'
      clotheColor={clotheColor}
      eyeType='Happy'
      eyebrowType='RaisedExcitedNatural'
      mouthType={face}
      skinColor='Light'
      style={{ width: 180, height: 180, zIndex:300 }}
        />
      </div>
  
        </div>
    )
}

export default AvatarR2
