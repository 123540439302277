import React,{useState} from 'react'
import Refer  from './Refer';
import { store } from 'react-notifications-component';

const mHover=  require("../../sounds/mouseclick.wav");
const mClick=  require("../../sounds/pressed.mp3");
const b1img = require('../../imgs/purple_16_.png');
const b2img = require('../../imgs/red_21_.png');
const b3img = require('../../imgs/green_14_.png');
const questimg = require('../../imgs/questsarea.png');
const howtoplay = require('../../imgs/howtoplay.png');
const storebg = require('../../imgs/store.png');
const referearn=require('../../imgs/referearn.png');
const b1style = {
    backgroundImage: `url(${b1img})`,
  };
  const b2style = {
    backgroundImage: `url(${b2img})`,
  };
  const b3style = {
    backgroundImage: `url(${b3img})`,
  };
  
  const playSound = audioFile => {
    audioFile.play();
  }
  
  
  const mclickAudio = new Audio(mClick);
  const mHoverAudio = new Audio(mHover);
  
  const showNotification=((success,msg)=>{
    if(success==true){
      store.addNotification({
        message: msg,
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: false
        }
      });
    }else{
      store.addNotification({
        message: msg,
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: false
        }
      });
    }
  });
  
  
function Modalplay(props) {
    const [howScreen,setHowScreen]=useState(false);
    const [storeScreen,setStoreScreen]=useState(false);
    const [referScreen,setReferScreen]=useState(false);
  
    return (
    <div>
            
        <div className="left">
            <div className="left1">
                <img src={questimg} className="imgcontain1"/>
        
                <div className="buttonsdiv" >
          
                    <button className="b1" style={b1style} onMouseOver={() => playSound(mHoverAudio)}    
                    onClick={()=>{      
                        playSound(mclickAudio);
                        setHowScreen(true);
                        setStoreScreen(false);
                        setReferScreen(false);
                        }}>
                        HOW TO?  
                    </button>
                    <button className="b2" style={b2style}  onClick={() => {
                        playSound(mclickAudio);
                        setStoreScreen(false);
                        setHowScreen(false);
                        if(props.eosio!=null)
                        setReferScreen(true);
                        else showNotification(false, "Sign in first to proceed");

                    }} onMouseOver={() => playSound(mHoverAudio)}>
                        REFER
                    </button>
                    <button className="b3" style={b3style}  onClick={() => {
                        playSound(mclickAudio);
                        setStoreScreen(true);
                        setHowScreen(false);
                        setReferScreen(false);
                    }} onMouseOver={() => playSound(mHoverAudio)}>
                        STORE
                    </button>

                </div>
            </div>
        </div>
   
  
    {
      howScreen==true && 
      <div style={{backgroundImage:`url(${howtoplay})`}} className="view100">
        <button className="cancelbutton" onClick={()=>{
          setHowScreen(false);
        }}></button>
        </div>
    } 

    {
      storeScreen==true && 
      <div style={{backgroundImage:`url(${storebg})`}} className="view100">
        <button className="cancelbutton" onClick={()=>{
                    setStoreScreen(false);
        }}></button>
        </div>
    }

      {referScreen==true && 
      <div style={{backgroundImage:`url(${referearn})`}} className="view100">
        <button className="cancelbutton" onClick={()=>{
                    setReferScreen(false);
        }}></button>

          <div className="referbuttons">
          <Refer accName={props.accountName.toLowerCase()} eosio={props.eosio}/>
          </div>

        </div>
        }
        </div>
    )
}

export default Modalplay
