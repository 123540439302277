import React,{useState,useEffect} from 'react'
import EOSIOClient from '../../services/EOSIOClient';
import {useHistory} from 'react-router-dom';
import './Pairing.css';
import { Spring } from 'react-spring/renderprops';
import EOSIOClientInstance from '../../services/EOSIOClientInstance';
import ProgressBar from 'react-customizable-progressbar'
import { store } from 'react-notifications-component';

const smartcontractacct=process.env.REACT_APP_SMART_CONTRACT;
const stonemodal = require('../../../imgs//stone_modal.png');
const mHover=  require("../../../sounds/mouseclick.wav");
const mClick=  require("../../../sounds/pressed.mp3");

const firebase = require("firebase");
require("firebase/functions");

const mclickAudio = new Audio(mClick);
const mHoverAudio = new Audio(mHover);
const playSound = audioFile => {
    audioFile.play();
}
  
function Pairing(props) {
    
    const fee=props.fee;
    const [accountName, setAccountName]=useState("battleshuman");
    const [timerwatch, setTimerwatch]=useState("00:00");
    const [title,setTitle]=useState("Requesting to Find Players");
    const [oppAcc,setOppAcc]=useState("Finding...");
    const [progress, setProgress]=useState(10);


    useEffect(() => {
        fetch('/__/firebase/init.json').then(async response => {
          firebase.initializeApp(await response.json());
        });
    }, []);


    const showNotification=((success,msg)=>{
      if(success==true){
        store.addNotification({
          message: msg,
          type: "success",
          insert: "bottom",
          container: "bottom-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: false
          }
        });
      }else{
        store.addNotification({
          message: msg,
          type: "danger",
          insert: "bottom",
          // background:{backrock},
          container: "bottom-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: false
          }
        });
      }
    });

    const history=useHistory();
    var timer=0;
    var game_id=0;
    var playerNo=0;
    const setPlayerNo=((pno)=>{
      playerNo=pno;
    });
    var oppname="Finding...";
    const setOpponentName=((_oppacc)=>{
      setOppAcc(_oppacc);
      oppname=_oppacc;
    });

    var eosio=(EOSIOClientInstance(0));
    // console.log("eosio:"+eosio);

    const setGameId=((gid)=>{
      game_id=gid;

    })

    useEffect(()=>{
      try{
      setAccountName(eosio.wax.userAccount);
      }catch(exception){
        
      }
      
      const interval1 = setInterval(() => {
          timer=timer+1;
          if(timer>305){
            checkrefundstatus(eosio,eosio.wax.userAccount);
          }
          updatetimer(timer+1);
      }, 1000);

      startFinding(eosio.wax.userAccount,eosio);
      const interval = setInterval(() => {
      checkstatus(eosio,eosio.wax.userAccount,interval);           
    }, 5000);

    return () => {
      clearInterval(interval1);
      clearInterval(interval)
    }
    
    },[]);

    const updatetimer=((timer)=>{
      var minutes = Math.floor(timer / 60);
      var seconds = timer - minutes * 60;
      var premin="";
      var presec="";
      if(minutes/10<1) premin="0";
      if(parseInt(seconds)/10<1) presec="0";
      setTimerwatch(premin+minutes+":"+presec+seconds);
    });


    var referfeesent=false;
    const sendreferfee=async(name, oppname, game_id, pno)=>{
      var referfee=(Number(fee.replace("WAX","").replace(" ",""))*0.05);
      // alert(referfee.toFixed(8) +" WAX");
      if(referfeesent==false){
        referfeesent=true;

        try {
          if(referfee!=0){
            var sendreferfeefn = firebase.functions().httpsCallable('sendreferfee');
            sendreferfeefn({game_id: game_id, pno:pno, fee:referfee }).then(function(result) {
              // Read result of the Cloud Function.
              props.setshowPrompt();
              history.push({
                  pathname: '/game',
                  state: [{name: name, oppname:oppname, game_id:game_id, playerNo:pno }]
                });
  

            }).catch(function(error) {
              // Getting the Error details.
              var code = error.code;
              var message = error.message;
              var details = error.details;
              // ...
            });
          }else{
            props.setshowPrompt();
            history.push({
                pathname: '/game',
                state: [{name: name, oppname:oppname, game_id:game_id, playerNo:pno }]
              });

          }
          
        } catch (err) {
          alert(err);
          referfeesent=false;

        }
    }   
  }



  var askedfee=false;
  const transactfee=((game_id,account)=>{
    if(askedfee==false){
      askedfee=true;
    const actionName = "transfer";
    const actionData = {
      from:account,
      to:smartcontractacct,
      quantity:fee,
      memo:game_id
    };

  
    // console.log(`Sending fee Transaction by ${accountName}!`);
    try {
    const result =  eosio.transaction(actionName, actionData,'eosio.token').then((r=>{
        // console.log(r+"\Fee pay on blockchain successful");
        showNotification(true,"Fee payment successfull");
    })
    ,(e=>{
        console.log("\nCaught exception in transaction: " + e);
        askedfee=false;
        showNotification(false,String(e))
       
        }));

    // console.log(result);

    } catch (e) {
        console.log("\nCaught exception: " + e);
        askedfee=false;
        // showNotification(false,String(e));
    }
  }

})

var askedrefund=false;

const transactRefund=((account)=>{
  if(askedrefund==false){
    askedrefund=true;
  const actionName = "refundfee";
  const actionData = {
    account:account,
  };

  console.log(`Sending refund Transaction by ${accountName}!`);
  try {
  const result =  eosio.transaction(actionName, actionData,smartcontractacct).then((r=>{
      // console.log(r+"\Fee pay on blockchain successful");
      showNotification(true,"Refund successfull");
  })
  ,(e=>{
      console.log("\nCaught exception in transaction: " + e);
      askedrefund=false;
      showNotification(false,String(e))
     
      }));

  // console.log(result);

  } catch (e) {
      console.log("\nCaught exception: " + e);
      askedrefund=false;
  }
}
})

const checkrefundstatus=((eosio,accountName)=>{
 
try{
  eosio.viewTableData(smartcontractacct,smartcontractacct,'accounts',accountName,accountName).then(r=>{
      // console.log(r);
     if(r.rows.length>=1){

      if(r.rows[0].status==1) 
      {
        showNotification(false,"Your 5 minutes waiting time was finished, no player found, try to play again!");
        props.setshowPrompt();
        history.push({
          pathname: '/',
          state: []
          });
      }
       else if(r.rows[0].status==2) 
        {
          setTitle("No fees paid");

          showNotification(false,"Your 5 minutes waiting time was finished, you didn't pay fees, so match abandoned");
          props.setshowPrompt();
          history.push({
            pathname: '/',
            state: []
            });
        }
        else if(r.rows[0].status==3){
          setTitle("Refund Initiating");

          showNotification(false,"Your 5 minutes waiting time was finished, other player didn't pay fees! Your refund is initiated");
          transactRefund(accountName);
          props.setshowPrompt();
          history.push({
            pathname: '/',
            state: []
            });

        }
      }    
// return () => clearInterval(interval);
})
}catch(exception){
console.log(exception);
}
})


    const checkstatus=((eosio,accountName,interval)=>{
      // console.log('This will run every 5 second!');
           
      try{
            eosio.viewTableData(smartcontractacct,smartcontractacct,'accounts',accountName,accountName).then(r=>{
                // console.log(r);
               if(r.rows.length>=1){
                  if(r.rows[0].status==2) 
                  {
                    setGameId(r.rows[0].game_id);

                    // setStatus(r.rows[0].status);
                    setTitle("Player found, Waiting for fee payments");
                    eosio.viewTableData(smartcontractacct,smartcontractacct,'gamesinfo',r.rows[0].game_id,r.rows[0].game_id).then(r=>{
                      // console.log(r);
                     if(r.rows.length>=1){
                         if(r.rows[0].player1==accountName) {setPlayerNo(1);}else {setPlayerNo(2);}
                          if(playerNo==1) setOpponentName(r.rows[0].player2);
                          else if(playerNo==2) setOpponentName(r.rows[0].player1);
                      }});      
                      transactfee(r.rows[0].game_id,accountName);
                      setProgress(60);
                  }
                  else if(r.rows[0].status==3){
                    setTitle("Wait upto 5 mins & Donot close the browser");
                    showNotification(true,"Waiting for other player to pay game fees")
                    setProgress(80);
                  }
                  else if(r.rows[0].status==4){
                    setTitle("Starting the Game");
                    setProgress(100);
                    if(game_id==0) {
                      // setGameId(r.rows[0].game_id);
                      var pno,_oppname;
                      eosio.viewTableData(smartcontractacct,smartcontractacct,'gamesinfo',r.rows[0].game_id,r.rows[0].game_id).then(r=>{
                        // console.log(r);
                       if(r.rows.length>=1){
                           if(r.rows[0].player1==accountName) {pno=1;}else {pno=2;}
                           if(pno==1) _oppname=(r.rows[0].player2);
                           else if(pno==2) _oppname=(r.rows[0].player1);

                            clearInterval(interval);

                            sendreferfee(accountName, _oppname, r.rows[0].game_id, pno);

                      
                          }});  
                      }else{
                          clearInterval(interval);

                          sendreferfee(accountName, oppname, game_id, playerNo);

                 
                      }  
                  }
                }    
          // return () => clearInterval(interval);
        })
      }catch(exception){
        console.log(exception);
      }
    })

    const startFinding=((acc,eosio)=>{
        const actionName = "joingame";
        const actionData = {
          account:acc,
          joining_fee:fee
        };
        
    
        // console.log(`Transaction by ${acc}!`);
        try {
        const result =  eosio.transaction(actionName, actionData,smartcontractacct).then((r=>{
            // console.log(r+"\nJoining request on blockchain successful");
            setProgress(25);
            showNotification(true,"Joining request accepted!");
        })
        ,(e=>{
            console.log("\nCaught exception in transaction: " + e);
            showNotification(false,String(e));
            }));

        // console.log(result);

        } catch (e) {
            console.log("\nCaught exception: " + e);
            // showNotification(false,String(e));
        }


    })


    return (
    <div>

         <Spring 
            from={{opacity:0.5, marginTop:-250}}
            to={{opacity:1, marginTop:25}}
            config={{duration:1000}}
            >
            {props1=>(
            <div  className="modalcontainer" style={props1} > 
            <img src={stonemodal} className="modal"/>
            <div className="modal">
             <h1 className="p1name">{accountName}</h1> 
             <h1 className="p2name">{oppAcc}</h1> 
             <h1 className="timer">{timerwatch}</h1> 


            <div className="status">
            <h1 className="statustxt">{title}</h1>

            <ProgressBar  
                radius={30}
                progress={progress}
                strokeWidth={8}
                strokeColor="#ffce54"
                strokeLinecap="butt"
                trackStrokeWidth={4}
                trackStrokeLinecap="butt"
                cut={120}
                rotate={-210}/>
                  
            </div> 
        </div>
    </div> )}

  </Spring> 


</div>
    )
}

export default Pairing