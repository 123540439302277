import React, { useState, useEffect } from 'react'
import './Refer.css';

import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WorkplaceShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  InstapaperIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  PinterestIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  WorkplaceIcon
} from "react-share";
import {
  FacebookShareCount,
  PinterestShareCount,
  RedditShareCount,
} from "react-share";

function Refer(props) {
  const shareUrl = 'https://battlesofhumanity.com/refer/account?name='+props.accName;
  const title = 'Use my referral link to play Battles Of Humanity Game & get rewarded upto 100 WAX tokens';
  const [rewardedreward,setrewardedreward]=useState(0);

  useEffect(()=>{
    try{
    props.eosio.viewTableData("battlsofhumn","gamereferral",'referacc',props.accName,props.accName).then(r=>{
      console.log(r);
      if(r.rows.length>=1)
      {
        var amt=(r.rows[0].r_reward).replace('WAX','');
        var reward=(Math.round(amt * 100) / 100).toFixed(2);
        setrewardedreward(reward);
      }
    })}catch(e){console.log(e);}
  },[]);

  return (
    <div className="totalreferhead">
    <a href={"https://wax-essential.com/referrals/account/"+props.accName+"/contract/battlsofhumn"} target="_blank">Check your Referral Dashboard</a>
  <div className="totalrefertxt"> Referrals Received: {rewardedreward} WAX
</div>
    <br/>
    <div style={{ width:"95%"}} >
      <div className="Demo__some-network">
        <FacebookShareButton
          url={shareUrl}
          quote={title}
          className="Demo__some-network__share-button"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>

        
      </div>

      <div className="Demo__some-network">
        <FacebookMessengerShareButton
          url={shareUrl}
          appId="521270401588372"
          className="Demo__some-network__share-button"
        >
          <FacebookMessengerIcon size={32} round />
        </FacebookMessengerShareButton>
      </div>

      <div className="Demo__some-network">
        <TwitterShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button"
        >
          <TwitterIcon size={32} round />
        </TwitterShareButton>

        <div className="Demo__some-network__share-count">&nbsp;</div>
      </div>

      <div className="Demo__some-network">
        <TelegramShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button"
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>

        <div className="Demo__some-network__share-count">&nbsp;</div>
      </div>

      <div className="Demo__some-network">
        <WhatsappShareButton
          url={shareUrl}
          title={title}
          separator=":: "
          className="Demo__some-network__share-button"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>

        <div className="Demo__some-network__share-count">&nbsp;</div>
      </div>

      <div className="Demo__some-network">
        <LinkedinShareButton url={shareUrl} className="Demo__some-network__share-button">
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>

     
      
      <div className="Demo__some-network">
        <RedditShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className="Demo__some-network__share-button"
        >
          <RedditIcon size={32} round />
        </RedditShareButton>

      </div>

     
      <div className="Demo__some-network">
        <EmailShareButton
          url={shareUrl}
          subject={title}
          body="body"
          className="Demo__some-network__share-button"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
     
      {/* <div className="Demo__some-network">
        <InstapaperShareButton
          url={shareUrl}
          title={title}
          className="Demo__some-network__share-button"
        >
          <InstapaperIcon size={32} round />
        </InstapaperShareButton>
      </div> */}
    </div>
    </div>
  );
}

 

export default Refer
