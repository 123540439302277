import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './fonts/High-Jinks.otf'
import { BrowserRouter as Router } from "react-router-dom";

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
// import Pairing from './components/pages/Pairing';
const loader = document.querySelector('.loader');

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide');
const hideLoader = () => loader.classList.add('loader--hide');

// var scale = 'scale(1)';
// document.body.style.webkitTransform =  scale;    // Chrome, Opera, Safari
//  document.body.style.msTransform =   scale;       // IE 9
//  document.body.style.transform = scale;     // General

// the setTimeout simulates the time it takes react to load, and is not part of the solution
setTimeout(() => 
  // the show/hide functions are passed as props
  ReactDOM.render(
    <Router>
      <App
        hideLoader={hideLoader}
        showLoader={showLoader} />
    </Router>,
    document.getElementById('root')
  )
, 1000);


// ReactDOM.render(
//   // <React.StrictMode>
//   <Router>
//     <App />
//     </Router>
//     ,
//   // </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
