import React from 'react'
import Avatar from 'avataaars'
import Typing from 'react-typing-animation';

function AvatarR1() {
    const avatartext="Hi, I am the host of this game. Welcome to the journey of Battles of Humanity!";
    const avatartext3="You have got 30 Battle Points to spend. Use them carefully!";
    const avatartext2="Let's start with ROUND 1, Category: TRAVEL";
    const face="Smile",clotheColor="PastelBlue";

    
    return (
        <div>
            <div className="bubblestyle">
            <Typing className="bubbletext" speed={50}>
            <Typing.Reset count={1} delay={0}/>
            { avatartext!="" &&  <span>{avatartext}</span>}
            { avatartext2!="" && <Typing.Reset count={1} delay={2000} />}
            { avatartext2!="" &&  <span>{avatartext2}</span>}
            { avatartext3!="" && <Typing.Reset count={1} delay={2000} />}
            { avatartext3!="" &&  <span>{avatartext3}</span>}

            </Typing>
   
            </div>
     <div className="avatarstyle">
     <Avatar
        avatarStyle='Transparent'
        topType='Hat'
        accessoriesType='Kurt'
        facialHairType='MoustacheMagnum'
        facialHairColor='Black'
        clotheType='CollarSweater'
        clotheColor={clotheColor}
        eyeType='Happy'
        eyebrowType='RaisedExcitedNatural'
        mouthType={face}
        skinColor='Light'
        style={{ width: 180, height: 180, zIndex:300 }}
      />
      </div>
  
        </div>
    )
}

export default AvatarR1
