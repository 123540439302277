import React from 'react'
const bar = require('../../imgs/bar/bar.png');

function BarGrid(props) {
    let i,j;
    var r=[];
    for(j=0;j<=5;j++){
        r[j]="bargrey";
    }
    
    const Rounds=props.RoundsData;

    if(Rounds!=undefined){
        for(i=0;i<Rounds.length;i++){
            if(Rounds[i]==1)
                r[i]="barrgreen";
            else if(Rounds[i]==-1) r[i]="barred";
            else if(Rounds[i]==0) r[i]="baryellow";

        }
    }
    
    return (
            <div className="bargrid">
                <h1 className={r[0]} >1</h1>
                <h1 className={r[1]}>2</h1>
                <h1 className={r[2]}>3</h1>
                <h1 className={r[3]}>4</h1>
                <h1 className={r[4]}>5</h1>
                {/* <h1 className={r[5]}>6</h1>
                <h1 className={r[6]}>7</h1> */}
        </div>
    )
}

export default BarGrid
