import React from 'react'
import Round1 from './Round1';
import Round2 from './Round2';
import Round3 from './Round3';
import Round4 from './Round4';
import Round5 from './Round5';


function Rounds(props) {
    switch(props.rno){
        case 1: return( <div>
          <Round1/></div>);
        case 2: return(
                 <Round2/>);
        case 3: return(
                 <Round3/>);
        case 4: return(
                 <Round4/>);
        case 5: return(
                 <Round5/>);
       
        default:  return(
          <div>
          <Round1/>
          </div>);
    
}
}

export default Rounds
