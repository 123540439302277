import React, { Component,useState,useEffect } from 'react';
import './SplashScreen.css';

import EOSIOClient from '../../services/EOSIOClient';
import EOSIOClientInstance from '../../services/EOSIOClientInstance';
import BottomHud from '../../BottomHud/BottomHud';
import ls from 'local-storage'
import { useMediaQuery } from 'react-responsive';

import { Spring } from 'react-spring/renderprops';

import { store } from 'react-notifications-component';
import GameScreen from '../GameScreen/GameScreen';
import {useHistory} from 'react-router-dom';
import ModalPlay from '../../elements/Modalplay';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  useLocation
} from "react-router-dom";
import PlayerStats from '../../elements/PlayerStats';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const smartcontractacct=process.env.REACT_APP_SMART_CONTRACT;
const bgmusic= require( "../../../sounds/bg.mp3");
const mHover=  require("../../../sounds/mouseclick.wav");
const mClick=  require("../../../sounds/pressed.mp3");

const b1img = require('../../../imgs/purple_16_.png');
const b2img = require('../../../imgs/red_21_.png');
const b3img = require('../../../imgs/green_14_.png');
const heading = require('../../../imgs/heading.png');
// const greenplay = require('../../imgs/greenplay.png');


const playSound = audioFile => {
  audioFile.play();
}


const mclickAudio = new Audio(mClick);
const mHoverAudio = new Audio(mHover);
const bgAudio=new Audio(bgmusic);

const showNotification=((success,msg)=>{
  if(success==true){
    store.addNotification({
      // title: "Wonderful!",
      message: msg,
      type: "success",
      insert: "bottom",
      // background:{backrock},
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: false
      }
    });
  }else{
    store.addNotification({
      // title: "Wonderful!",
      message: msg,
      type: "warning",
      insert: "bottom",
      // background:{backrock},
      container: "bottom-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 4000,
        onScreen: false
      }
    });
  }
});


export default function SplashScreen(props) {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1080px)'
  })

  var eosio=EOSIOClientInstance(0);
  const [accountName, setAccountName]=useState('YOU');
  const [balance, setBalance]=useState(0.00);
  const [rewardWin, setRewardWin]=useState(0.00);
  const [playsNo, setPlaysNo]=useState(0);
  const [rank, setRank]=useState(0);
  const [loggedIn, setLoggedIn]=useState(false);
  const [refertxt,setRefertxt]=useState("Login & Complete the transaction for referral reward");


  const history=useHistory();
  let query = useQuery();
  var name=query.get("name");

  // console.log("eosio:"+eosio);
  
  const transactRefer=((to,from,eosio)=>{

    const actionName = "referred";
    const actionData = {
      to:to,
      from:from,
      gameAcct:smartcontractacct
    };
  
    try {
      const result =  eosio.transaction(actionName, actionData,'gamereferral').then((r=>{
          console.log(r+"\Referral on blockchain successful from:"+from);
          setRefertxt("Refer successfully applied");
      })
      ,(e=>{
          console.log("\nCaught exception in transaction: " + e);
        }));
  
          // console.log(result);
  
        } catch (e) {
            console.log("\nCaught exception: " + e);
            // showNotification(false,String(e));
        }
      
    
    })
  
  const setLoginDetails=((_eosio,success)=>{
    if(success==true){
      setAccountName((_eosio.wax.userAccount).toUpperCase());
      setLoggedIn(true);
      if(eosio==null)
      { eosio= _eosio;
        ls.set('_eosio',_eosio);

        EOSIOClientInstance(1,_eosio);
      }
      // console.log('ACC:'+_eosio.wax.userAccount);
      _eosio.viewTableData(_eosio.wax.userAccount,'eosio.token','accounts').then(r=>{
      // console.log('hi'+r);
      if(r.rows.length==1){
        var amt=(r.rows[0].balance).replace('WAX','');
        // console.log(amt);
        var bal=(Math.round(amt * 100) / 100).toFixed(2);
        // console.log(bal);
        
        setBalance(bal);
      }
    }).catch(e=>{
      console.log(e);
    })


  _eosio.viewTableData(smartcontractacct,smartcontractacct,'accounts',_eosio.wax.userAccount,_eosio.wax.userAccount).then(r=>{
        // console.log(r);
        if(r.rows.length>=1)
        {
          var amt=(r.rows[0].rewardswon).replace('WAX','');
          var reward=(Math.round(amt * 100) / 100).toFixed(2);
          setRewardWin(reward);
          setPlaysNo(r.rows[0].gamesplayed);
        }
      })
  
    _eosio.viewTableData(smartcontractacct,smartcontractacct,'accounts',0,100000000000,50,2).then(r=>{
        // console.log(r);
        if(r.rows.length>0)
        {
          var rank=(r.rows.length);
          var i=r.rows.length-1;
          for( i;i>=0;i--){
            // console.log(r.rows[i].account+":"+_eosio.wax.userAccount);
            if(r.rows[i].account==_eosio.wax.userAccount){
                rank=r.rows.length-i;
                break;
              }
          }
          var postrank="th";
          var ldigit=rank%10;
          if(ldigit==1)  postrank="st";
          else if(ldigit==2)  postrank="nd";
          else if(ldigit==3)  postrank="rd";
          else postrank="th";

          setRank(rank + postrank);
  
        }
      })

      showNotification(true,"Sign in successfull");
      showNotification(false,"Make sure Popups are allowed in your browser.");
      if(props.referred==1){
        transactRefer(_eosio.wax.userAccount,name,_eosio);
      }
    }

  });
    

  useEffect(()=>{
    if(eosio!=null){
      setLoginDetails(eosio,true);
    }
  },[])

  
  return (


  <div>
    {isDesktopOrLaptop &&
    <>
    <div className="screen">
    
    <div style={{flex:1}}>
    <ModalPlay eosio={eosio} accountName={accountName}/>
    </div>
   
   
    <div style={{flex:1}}>
      <Spring 
        from={{opacity:0.5, marginTop:0}}
        to={{opacity:1, marginTop:-150}}
        config={{duration:1000}}>

        {props1=>(
          <div  className="splashimg" style={props1} > 
          <img src={heading} className="splashimg1"/>
          
          {loggedIn==false && <button className="loginbutton" style={{ textDecoration: 'none' }} onMouseOver={() => playSound(mHoverAudio)}    
            onClick={()=>{      
              playSound(mclickAudio);
              new EOSIOClient({setLoginDetails})
              }}>LOGIN
              </button>}
          

          {
            loggedIn==true && <button className="playbutton" style={{ textDecoration: 'none' }} onMouseOver={() => playSound(mHoverAudio)}    
            onClick={()=>{     
              playSound(mclickAudio);
              history.push({
                pathname: '/room',
                state: [{accountName: accountName, balance: balance, rewardWin:rewardWin, 
                  playsNo:playsNo,rank:rank}]
              })
              playSound(bgAudio);
              }}>PLAY
              </button>
          }
            
          
        {name ? (
          <h3 className="refertxt">{refertxt}</h3>
        ):<h3></h3> }

          </div>)}
        </Spring>
      </div>



    <div style={{flex:1}}>

    <PlayerStats rank={rank} rewardWin={rewardWin} playsNo={playsNo} accountName={accountName} balance={balance}/>
    </div>

  </div>

    <div >
    <footer >
      <BottomHud start="false" oppwins="0" mywins="0"/>
    </footer>
    </div>
    </>}
    
    {!isDesktopOrLaptop &&
      <h3 className="refertxt" style={{position:'absolute',top:'50%',height:'100%'}}>
        The current version of the game is only supported in the Desktop, PC, or Laptop browsers! We are actively working to bring it to mobile as well. Thanks for your interest!      </h3>
    }

    </div>
  )
}