import React from 'react'
import Avatar from 'avataaars'
import Typing from 'react-typing-animation';

function AvatarResult(props) {
    var avatartext="",avatartext2="",avatartext3="";
    var face="Disbelief";
    avatartext="Good Bye! Come back again to win other games. Did you know...";

    var textArray = [
       "It takes more energy to make 1 kg of paper than it takes to make 1 kg of steel",
       "It takes almost 500,000 litres of water to extract just 1 kg of gold",
       "Around 16,000 litres of water is consumed to make just 1 kg of beef",
       "It takes around 2,700 litres of water to make a single cotton t-shirt",
       "1 tonne of CO2e is enough to power a UK light bulb for 10 years. In France it’s enough for over 50 years",
       "Transporting goods by a rigid road truck is over 70 times more carbon intensive than bulk shipping",
       "Recycling a single 500 ml aluminium beer can saves enough energy to power a TV for over 4 hours",
       "Switching from the font ‘Arial’ to ‘Century Gothic’ saves 30% ink on printing",
       "More energy is wasted in the UK’s electricity generation system than to heat all of the UK’s homes",
       "Water efficient fixtures can cut water use by 30 percent",
       "Running tap water for two minutes is equal to 3-5 gallons of water",
       "Every time you open the refrigerator door, up to 30 percent of the cold air can escape",
       "America uses about 15 times more energy per person than the typical developing country",
       "A heavy coat of dust on a light bulb can block up to half of the light",
       "Recycling one ton of paper saves 17 trees",
       "Recycled paper produces about 25% fewer total emissions than conventional paper",
       "Energy is the dominant contributor to climate change, accounting for around 60% of total GHG emissions",
       "70% of the world is covered by water, yet only 2.5% of it is fresh & only 1% is accessible for human use.",
       "Recycling one aluminum can can provide enough energy to run a TV for 3 hours",
        "Every time you open a refrigerator door, up to 30% of the cold air can escape",
        "By leaving computer monitors on overnight, 9 million tons of carbon dioxide emissions are wasted every year",
        "By going vegan, one person can save approximately 829.000 (219.000 US gal) of water a year",
        "27,000 trees are cut down each day so we can have Toilet Paper",
        "Rainforests are cut down at a rate of 100 acres per minute",
        "The world’s oldest trees are more than 4,600 years old",
        "Landfills are composed of 35% packaging materials",
        "A glass bottle made in our time will take more than 4,000 years to decompose"
    ];
    
    var randomNumber = Math.floor(Math.random()*textArray.length);
    avatartext2=textArray[randomNumber];

    return (
        <div>
            
            <div className="bubblestyledark">
            <Typing className="bubbletextdark" speed={50}>
            { avatartext!="" &&  <span>{avatartext}</span>}
            { avatartext2!="" && <Typing.Reset count={1} delay={2000} />}
            { avatartext2!="" &&  <span>{avatartext2}</span>}
         
            </Typing>
            </div>
     <div className="avatarstyle">
     <Avatar
        avatarStyle='Transparent'
        topType='Hat'
        accessoriesType='Blank'
        facialHairType='MoustacheMagnum'
        facialHairColor='Black'
        clotheType='CollarSweater'
        clotheColor='White'
        eyeType='EyeRoll'
        eyebrowType='RaisedExcitedNatural'
        mouthType={face}
        skinColor='Light'
        style={{ width: 180, height: 180, zIndex:300 }}
      />

      </div>
  
        </div>
    )
}

export default AvatarResult
