import React from 'react'
import Avatar from 'avataaars'
import Typing from 'react-typing-animation';

function AvatarR2(props) {
    var avatartext="",avatartext2="",avatartext3="";
    var face="Smile",clotheColor="PastelBlue";
    const RoundsData=props.RoundsData;

    if(RoundsData.length>=2){
            if(RoundsData[1]==1){
              clotheColor="PastelGreen";
              avatartext="You won the 2nd round. The game got exciting!";
            }else if(RoundsData[1]==-1){
                face="Concerned";
                clotheColor="Red";
              avatartext="You lost the 2nd round. You can still win the game by winning others";
          }else if(RoundsData[1]==0){
            face="Twinkle";
              avatartext="The 2nd round was draw. All the best for remaining rounds!";
          }
        }
    avatartext2=("Let's start with ROUND 3, Category: CLOTHES");
        
    return (
        <div>
            
            <div className="bubblestyle">
            <Typing className="bubbletext" speed={50}>
            <Typing.Reset count={1} delay={0}/>

            { avatartext!="" &&  <span>{avatartext}</span>}
            { avatartext2!="" && <Typing.Reset count={1} delay={2000} />}
            { avatartext2!="" &&  <span>{avatartext2}</span>}
           
            </Typing>
   </div>
     <div className="avatarstyle">
     <Avatar
      avatarStyle='Transparent'
      topType='Hat'
      accessoriesType='Kurt'
      facialHairType='MoustacheMagnum'
      facialHairColor='Black'
      clotheType='CollarSweater'
      clotheColor={clotheColor}
      eyeType='Happy'
      eyebrowType='RaisedExcitedNatural'
      mouthType={face}
      skinColor='Light'
      style={{ width: 180, height: 180, zIndex:300 }}
        />
      </div>
  
        </div>
    )
}

export default AvatarR2
