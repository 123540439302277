import React from 'react';
import './bottomhudstyle.css';
import BarGrid from './BarGrid';

function BottomHud(props) {
    const bottomhud = require('../../imgs/bottom_hud.svg');
    const base = require('../../imgs/bar/base.png');
    const lstat = require('../../imgs/bar/lstats.png');
    const rstat = require('../../imgs/bar/rstats.png');
    const bar = require('../../imgs/bar/bar.png');
    var r1=false;
    var r1win=r1? "barrgreen":"barred";

    if(props.start=="false"){
        return (
            <div>
                 <div className="bottomhud">
                <div className="imgfooter">
                <img src={base} className="bbase"/>
                <img src={lstat} className="lstats"/>
                <img src={rstat} className="rstats"/>
                </div>
                </div>
            </div>
        )
    
    }else{

        return (
            <div>
                <div  className="bottomhud">
                <div className="imgfooter">
                <img src={base} className="bbase"/>
                <div className="lplayer">{props.myname}</div>
                <img src={lstat} className="lstats"/>
                <div className="rplayer">{props.oppname}</div>
                <img src={rstat} className="rstats"/>
                <div className="rtoptxt">WINS: {props.oppwins}/5</div>
                <div className="rbottomtxt">BP: {props.oppbpoints}/30</div>
                <div className="ltoptxt">WINS: {props.mywins}/5</div>
                <div className="lbottomtxt">BP: {props.mybpoints}/30</div>

                {/* <h1 className="ltoptxt">1/7</h1>
                <h1 className="lbottomtxt">WINS</h1>

    */}

                <BarGrid RoundsData={props.RoundsData}/>
                <img src={bar} className="bar"/>

                {/* <img src={bar} className="barr2"/>
                <img src={bar} className="barr3"/>
                <img src={bar} className="barr4"/>
                <img src={bar} className="barr5"/>
                <img src={bar} className="barr6"/>
                <img src={bar} className="barr7"/> */}


                </div>
                </div>
            </div>
        )
    }
}

export default BottomHud
