import React from 'react'
import { Spring } from 'react-spring/renderprops';
import {useHistory} from 'react-router-dom';
import './ResultScreen.css'
const winScreen=require('../../../imgs/result/winscreen.png');
const drawScreen=require('../../../imgs/result/drawscreen.png');
const lostScreen=require('../../../imgs/result/lostscreen.png');

function ResultScreen(props) {
    const history=useHistory();

    var chooseimg=winScreen;

    if(props.win==1){
        chooseimg=winScreen;
    }else if(props.win==2)
        chooseimg=lostScreen;
    else if(props.win==3)
        chooseimg=drawScreen;

    return (
        <div>
             <Spring 
            from={{opacity:0.5, marginTop:-250}}
            to={{opacity:1, marginTop:0}}
            config={{duration:1000}}
            >
            {props1=>(
            <div  className="modalwincontainer" style={props1} > 
            <div style={{backgroundImage:`url(${chooseimg})`}} className="modalwin">
                <div className="quitbuttonscontainer">
            <button className="quitbutton" onClick={()=>{
                  history.push({
                    pathname: '/',
                  });
                }
            }>QUIT</button> 
            <button className="sharebutton" onClick={            
                ()=>{
                    window.open("https://www.dapp.com/dapp/battles-of-humanity", "_blank")}
                }

            title={"Show us some love"}
            >RATE US</button>
            </div>
            </div>
                </div> 
                )}
        </Spring>
        </div>
    )
}

export default ResultScreen
