import React from 'react'
import AvatarR1 from './AvatarR1';
import AvatarR2 from './AvatarR2';
import AvatarR3 from './AvatarR3';
import AvatarR4 from './AvatarR4';
import AvatarR5 from './AvatarR5';
import AvatarResult from './AvatarResult';

function AvatarHost(props) {

    var round=props.round;
    
    if(round==1){
      return(
      <AvatarR1 RoundsData={props.RoundsData}/>)
    }else if(round==2){return(
      <AvatarR2 RoundsData={props.RoundsData}/>)
    }
    else if(round==3){return(
      <AvatarR3 RoundsData={props.RoundsData}/>)
    }else if(round==4){return(
      <AvatarR4 RoundsData={props.RoundsData}/>)
    }else if(round==5){return(
      <AvatarR5 RoundsData={props.RoundsData}/>)
    }else{return(
      <AvatarResult RoundsData={props.RoundsData}/>)
    }

}

export default React.memo(AvatarHost)
