
var eos=null;

function EOSIOClientInstance(_set,eosio) {
    if(_set==1){
     eos=eosio;}else
    return (
        eos
        )
}

export default EOSIOClientInstance
